import React from 'react';

import { TChatWidgetId } from '../../store-chat-widget';
import { ISticker } from '../../types';
import { ICustomInputProps, ICustomInputRef } from '../custom-input/custom-input.type';
import { TEmojiPickerVariant, TEmojiStickerGroup } from '../emoji-sticker-picker/emoji-sticker-picker.type';
import { IMenuProps } from '../menu/menu.type';

export interface IMessageInputProps {
  widgetId: TChatWidgetId;
  className?: string;
  placeholder?: string;
  headChildren?: React.ReactNode[] | React.ReactNode;
  tailChildren?: React.ReactNode[] | React.ReactNode;

  enableEmoji?: boolean;
  menu?: IMenuProps;
  emojiStickerData?: TEmojiStickerGroup[];
  emojiPickerVariant?: TEmojiPickerVariant;
  onSubmit?: (content: string | undefined) => void;
  onStickerClick?: (sticker: ISticker) => void;
  onChange?: (value: string) => void;
  onInputClick?: ICustomInputProps['onClick'];
  onEmojiPickerClick?: (event: React.MouseEvent) => void;
}

export interface IMessageInputRef extends Omit<ICustomInputRef, 'getText' | 'insertContentAtCaret' | 'focus'> {
  getValue: () => string;
  setValue: (value: string) => void;
  hideEmojiPicker: () => void;
  addMention: (username: string) => void;
}
