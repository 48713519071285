import { IEmoji } from '../types';

export const EMOJI_REGEX_STRING = ':[a-z0-9_]+:';

export function createEmojiMap(emojis: IEmoji[]): Record<string, IEmoji> {
  return emojis.reduce(
    (acc, emoji) => {
      acc[emoji.name] = emoji;
      return acc;
    },
    {} as Record<string, IEmoji>
  );
}

export function replaceAllTextEmojis(text: string, emojiMap: Record<string, IEmoji>): string {
  const regex = new RegExp(EMOJI_REGEX_STRING, 'g');

  if (emojiMap) {
    text = text.replace(regex, (match, p1) => {
      const emoji = emojiMap[p1];
      if (!emoji?.isCustom) {
        return match;
      }

      return getCustomEmojiHTML(emoji);
    });
  }

  return text;
}

export function getEmojiHTML(emoji: IEmoji): string {
  if (!emoji.isCustom) {
    return emoji.name;
  }

  return getCustomEmojiHTML(emoji);
}

function getCustomEmojiHTML(emoji: IEmoji): string {
  return `<img class="custom-emoji" data-emoji="${emoji.name}" src="${emoji.imageUrl}" />`;
}
