import { clsx } from 'clsx';
import { sum } from 'lodash';
import { observer } from 'mobx-react-lite';
import { CSSProperties, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';

import { ChatHeader } from '../chat-header/chat-header.component';
import { DialogManager } from '../dialog/dialog-manager.component';
import { FloatStackManager } from '../float-stack/float-stack-manager.component';
import { FloatManager } from '../float/float-manager.component';
import { MessageInput } from '../message-input/message-input.component';
import { IMessageInputRef } from '../message-input/message-input.type';
import { Message } from '../message/message.component';
import './chat-widget.style.scss';
import { IChatWidgetProps, IChatWidgetRef } from './chat-widget.type';

const classNamePrefix = 'tt-ui-chat-widget__chat-widget';

/**
 * TODO: implement load more messages
 */
export const ChatWidget = observer(
  forwardRef<IChatWidgetRef, IChatWidgetProps>((props, ref) => {
    const {
      id,
      canLoadMore,
      className,
      children,
      messages = [],
      autoScrollBottomThreshold = 100,
      onSubmit,
      hideInput,
      hideMessageList,
      inputHeadChildren,
      inputTailChildren,
      inputPlaceholder,
      menu,
      enableEmoji = true,
      header,
      animatedOnInit,
      emojiStickerData,
      emojiPickerVariant,
      messageRender,
      onInputChange,
      onInputClick,
      onStickerClick,
      onScrolledToBottomChange,
      onEmojiPickerClick,
      onLoadMore
    } = props;

    const innerContentRef = useRef<HTMLDivElement>(null);
    const messageListRef = useRef<VariableSizeList>(null);
    const messageListOuterRef = useRef<HTMLDivElement>(null);
    const messageListInnerRef = useRef<HTMLDivElement>(null);

    const messageInputRef = useRef<IMessageInputRef>(null);

    const [readyRenderMessageList, setReadyRenderMessageList] = useState<boolean>();

    const [heightByIndex, setHeightByIndex] = useState<Record<number, number>>({});

    const [canAutoScrollToBottom, setCanAutoScrollToBottom] = useState<boolean>(true);

    const scrollToBottom = useCallback(() => {
      messageListRef.current?.scrollToItem(messages.length, 'end');
      messageListRef.current?.resetAfterIndex(messages.length - 2, true);
    }, [messages.length]);

    useImperativeHandle(ref, () => {
      return {
        scrollToBottom,
        getInputValue: () => messageInputRef.current?.getValue() ?? '',
        setInputValue: value => {
          messageInputRef.current?.setValue(value);
        },
        addMention: username => messageInputRef.current?.addMention(username)
      };
    }, [scrollToBottom]);

    const setItemHeightByIndex = useCallback((index: number, height: number) => {
      setHeightByIndex(prev => ({ ...prev, [index]: height }));
    }, []);

    const getItemHeightByIndex = useCallback(
      (index: number) => {
        return heightByIndex[index] ?? 80;
      },
      [heightByIndex]
    );

    const renderVirtualMessage = useCallback(
      ({ index, style }: { index: number; style: CSSProperties }) => {
        const message = messages[index];

        return (
          <Message
            {...message}
            index={index}
            style={style}
            setHeightByIndex={setItemHeightByIndex}
            messageRender={message.messageRender ?? messageRender}
          />
        );
      },
      [messageRender, messages, setItemHeightByIndex]
    );

    useEffect(() => {
      if (readyRenderMessageList) {
        setTimeout(() => {
          messageListRef.current?.resetAfterIndex(0, true);
        }, 1000);
      }
    }, [readyRenderMessageList, scrollToBottom]);

    // auto scroll to bottom when add new messages
    useEffect(() => {
      if (messages.length > 0 && readyRenderMessageList) {
        const _timeout = setTimeout(() => {
          if (canAutoScrollToBottom) scrollToBottom();
        }, 100);

        return () => clearTimeout(_timeout);
      }
    }, [canAutoScrollToBottom, messages.length, readyRenderMessageList, scrollToBottom]);

    useEffect(() => {
      if (!animatedOnInit) setReadyRenderMessageList(true);
    }, [animatedOnInit]);

    useEffect(() => {
      if (hideMessageList) {
        messageInputRef.current?.hideEmojiPicker();
      }
    }, [hideMessageList]);

    return (
      <div
        className={clsx(classNamePrefix, className)}
        onAnimationEnd={() => {
          if (animatedOnInit) setReadyRenderMessageList(true);
        }}
      >
        {header && <ChatHeader {...header} />}
        {!hideMessageList && (
          <div className={`${classNamePrefix}__inner-content`} ref={innerContentRef}>
            {readyRenderMessageList && (
              <AutoSizer>
                {({ width, height }) => (
                  <VariableSizeList
                    className={`${classNamePrefix}__message-list`}
                    ref={messageListRef}
                    outerRef={messageListOuterRef}
                    innerRef={messageListInnerRef}
                    itemCount={messages.length}
                    itemSize={getItemHeightByIndex}
                    height={height}
                    width={width}
                    layout="vertical"
                    onItemsRendered={() => {
                      messageListRef.current?.resetAfterIndex(0, true);
                    }}
                    overscanCount={1}
                    onScroll={() => {
                      const newValue =
                        sum([
                          messageListOuterRef.current?.scrollTop,
                          messageListOuterRef.current?.clientHeight,
                          getItemHeightByIndex(messages.length - 1)
                        ]) +
                          autoScrollBottomThreshold >=
                        (messageListOuterRef.current?.scrollHeight ?? 0);

                      if (canAutoScrollToBottom !== newValue) {
                        setCanAutoScrollToBottom(newValue);
                        onScrolledToBottomChange?.(newValue);
                      }

                      if (!messageListOuterRef.current?.scrollTop && canLoadMore) {
                        onLoadMore?.();
                      }
                    }}
                  >
                    {renderVirtualMessage}
                  </VariableSizeList>
                )}
              </AutoSizer>
            )}

            {children}
            <FloatManager widgetId={id} />
            <FloatStackManager widgetId={id} />
          </div>
        )}
        <DialogManager widgetId={id} />

        {!hideInput && (
          <MessageInput
            widgetId={id}
            className={`${classNamePrefix}__message-input`}
            ref={messageInputRef}
            onSubmit={onSubmit}
            headChildren={inputHeadChildren}
            tailChildren={inputTailChildren}
            placeholder={inputPlaceholder}
            enableEmoji={enableEmoji}
            emojiStickerData={emojiStickerData}
            emojiPickerVariant={emojiPickerVariant}
            menu={menu}
            onChange={onInputChange}
            onStickerClick={onStickerClick}
            onInputClick={onInputClick}
            onEmojiPickerClick={onEmojiPickerClick}
          />
        )}
      </div>
    );
  })
);
