export interface ICustomInputProps {
  onClick?: (event: React.MouseEvent) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (sanitizedText: string) => void;
  onEnter?: (event: React.KeyboardEvent) => void;
  placeholder?: string;
  style?: {
    borderRadius?: number;
    color?: string;
    borderColor?: string;
    fontSize?: number;
    fontFamily?: string;
    background: string;
    placeholderColor?: string;
  };
  tabIndex?: number;
  className?: string;
  onChangeHtml?: (html: string) => void;
}

export interface ICustomInputRef {
  insertContentAtCaret: (html: string) => void;
  setHtml: (value: string) => void;
  getHtml: () => string;
  getText: () => string;
  focus: () => void;
}
