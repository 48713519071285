import { clsx } from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef } from 'react';

import { ChatWidgetStore } from '../../store-chat-widget';
import { IEmoji } from '../../types';
import { getEmojiHTML, getMentionHTML } from '../../util';
import { ButtonGhostPrimary } from '../button-ghost-primary/button-ghost-primary.component';
import { ButtonGhost } from '../button-ghost/button-ghost.component';
import { CustomInput } from '../custom-input/custom-input.component';
import { ICustomInputRef } from '../custom-input/custom-input.type';
import { EmojiStickerPicker } from '../emoji-sticker-picker/emoji-sticker-picker.component';
import { Menu } from '../menu/menu.component';
import './message-input.style.scss';
import { IMessageInputProps, IMessageInputRef } from './message-input.type';

const classNamePrefix = 'tt-ui-chat-widget__message-input';

const EMOJI_DIALOG_ID = 'tt-ui-chat-widget__message-input-emoji-widget';

export const MessageInput = observer(
  forwardRef<IMessageInputRef, IMessageInputProps>((props, ref) => {
    const {
      widgetId,
      className,
      placeholder = 'Input message...',
      headChildren = [],
      tailChildren = [],
      enableEmoji = false,
      emojiPickerVariant = 'emoji',
      menu,
      emojiStickerData,
      onSubmit,
      onStickerClick,
      onChange,
      onInputClick,
      onEmojiPickerClick
    } = props;

    const editorRef = useRef<ICustomInputRef>(null);
    const emojiPickerDialog = ChatWidgetStore.dialogSelector(widgetId, EMOJI_DIALOG_ID);

    const _headChildren = useMemo(() => {
      return Array.isArray(headChildren) ? headChildren : [headChildren];
    }, [headChildren]);

    const _tailChildren = useMemo(() => {
      return Array.isArray(tailChildren) ? tailChildren : [tailChildren];
    }, [tailChildren]);

    const closeEmojiPicker = useCallback(() => {
      if (enableEmoji) {
        ChatWidgetStore.closeDialogAction(widgetId, EMOJI_DIALOG_ID);
      }
    }, [enableEmoji, widgetId]);

    const _onSubmit = useCallback(() => {
      try {
        const text = editorRef.current?.getText().trim();
        if (!text) return;

        onSubmit?.(text);

        closeEmojiPicker();
      } catch (error) {
        console.log('[Chat input] onSubmit:', error);
      } finally {
        if (editorRef.current) editorRef.current.setHtml('');
      }
    }, [closeEmojiPicker, onSubmit]);

    const _onInputClick = useCallback(
      (event: React.MouseEvent) => {
        onInputClick?.(event);
        closeEmojiPicker();
      },
      [onInputClick, closeEmojiPicker]
    );

    useImperativeHandle(ref, () => {
      return {
        addMention: username => editorRef.current?.insertContentAtCaret(getMentionHTML(username)),
        getValue: () => editorRef.current?.getText() ?? '',
        setValue: value => {
          editorRef.current?.setHtml(value);
        },
        setHtml: value => {
          editorRef.current?.setHtml(value);
        },
        getHtml: () => {
          return editorRef.current?.getHtml() ?? '';
        },
        hideEmojiPicker: closeEmojiPicker
      };
    }, [closeEmojiPicker]);

    /** enable emoji picker */
    useEffect(() => {
      if (!enableEmoji) return;

      ChatWidgetStore.addDialogAction(widgetId, {
        widgetId,
        id: EMOJI_DIALOG_ID,
        onOpen: () => {
          editorRef.current?.focus();
        },
        children: (
          <EmojiStickerPicker
            emojiStickerGroups={emojiStickerData}
            onEmojiClick={(emoji: IEmoji) => {
              editorRef.current?.insertContentAtCaret(getEmojiHTML(emoji));
            }}
            variant={emojiPickerVariant}
            onStickerClick={sticker => {
              closeEmojiPicker();
              onStickerClick?.(sticker);
            }}
          />
        ),
        verticalAlign: 'bottom'
      });
    }, [widgetId, enableEmoji, emojiStickerData, onStickerClick, closeEmojiPicker, emojiPickerVariant]);

    /** add menu dialog */
    useEffect(() => {
      //
    }, []);

    return (
      <div className={clsx(classNamePrefix, className)}>
        <div className={`${classNamePrefix}__head-wrapper`}>
          {menu != null && <Menu {...menu} />}

          {_headChildren.map((headChild, index) => {
            return <React.Fragment key={index}>{headChild}</React.Fragment>;
          })}
        </div>

        <CustomInput
          className={`${classNamePrefix}__editor`}
          ref={editorRef}
          onEnter={_onSubmit}
          onChange={onChange}
          onClick={_onInputClick}
          placeholder={placeholder}
        />

        <div className={`${classNamePrefix}__tail-wrapper`}>
          {_tailChildren.map((tailChild, index) => {
            return <React.Fragment key={index}>{tailChild}</React.Fragment>;
          })}

          {enableEmoji && (
            <ButtonGhost
              onClick={e => {
                onEmojiPickerClick?.(e);

                if (e.defaultPrevented) {
                  return;
                }

                if (emojiPickerDialog?.isOpen) {
                  ChatWidgetStore.closeDialogAction(widgetId, EMOJI_DIALOG_ID);
                } else {
                  ChatWidgetStore.openDialogAction(widgetId, EMOJI_DIALOG_ID);
                }
              }}
            >
              <span className="material-symbols-outlined icon">mood</span>
            </ButtonGhost>
          )}

          <ButtonGhostPrimary onClick={() => _onSubmit()}>
            <span className="material-symbols-outlined icon">Send</span>
          </ButtonGhostPrimary>
        </div>
      </div>
    );
  })
);
