import { ISticker } from '../types';

export const STICKER_REGEX_STRING = '#\\[\\[sticker:([a-z0-9_]+)\\]\\]#';

export function getStickerName(text: string) {
  const match = new RegExp(STICKER_REGEX_STRING, 'g').exec(text);
  if (match) {
    return match[1];
  }
  return null;
}

export function getStickerMessage(sticker: ISticker) {
  return `#[[sticker:${sticker.name}]]#`;
}
