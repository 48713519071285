/**
 * @param {string} html - HTML string to be pasted at the caret position
 */
export function handlePasteHtmlAtCaret(html: string): void {
  if (window.getSelection) {
    const selection = window.getSelection();

    if (selection === null) return;

    if (selection.getRangeAt && selection.rangeCount) {
      const range = selection.getRangeAt(0);
      range.deleteContents();

      // Range.createContextualFragment() would be useful here but is
      // non-standard and not supported in all browsers (IE9, for one)
      const el = document.createElement('div');
      el.innerHTML = html;
      const frag = document.createDocumentFragment();
      let node;
      let lastNode;
      while ((node = el.firstChild)) {
        lastNode = frag.appendChild(node);
      }
      range.insertNode(frag);

      // Preserve the selection
      if (lastNode) {
        range.setStartAfter(lastNode);
        range.collapse(true);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
  }
}

export function replaceImageWithEmoji(container: HTMLDivElement): HTMLDivElement {
  const images = Array.from(container.querySelectorAll('img'));

  images.forEach(image => {
    image.outerHTML = `:${image.dataset.emoji}:` || '';
  });

  return container;
}

export function getSelectionStart(range: Range) {
  let node: Node | null = range.startContainer;
  let offset = range.startOffset;

  // Handle cases where the selection start node is not a text node
  if (node.nodeType !== Node.TEXT_NODE) {
    while (node.nodeType !== Node.TEXT_NODE) {
      node = node.nextSibling;
      if (!node) break;
    }
    if (!node) {
      node = range.commonAncestorContainer;
      while (node && node.nodeType !== Node.TEXT_NODE) {
        node = node?.firstChild || null;
      }
    }
    offset = 0;
  }

  return { node, offset };
}

export function getCursor() {
  const selection = window.getSelection();
  const range = selection?.getRangeAt(0);
  if (!range) {
    throw new Error('No range found');
  }
  const selectionStart = getSelectionStart(range);

  return { selection, range, selectionStart };
}

export function addLineBreak(): void {
  const { selection, range, selectionStart } = getCursor();
  if (!selection || !selectionStart.node) return;

  // If cursor is at the end of the text content, add one more line break
  if (selection.isCollapsed && selectionStart.offset === selectionStart.node.textContent?.length) {
    const br = document.createElement('br');
    range.insertNode(br);
    range.setStartAfter(br);
    range.setEndAfter(br);
    selection.removeAllRanges();
    selection.addRange(range);

    const br2 = document.createElement('br');
    range.insertNode(br2);
    range.setStartAfter(br2);
    range.setEndAfter(br2);
    selection.removeAllRanges();
    selection.addRange(range);
  } else {
    const br = document.createElement('br');
    range.insertNode(br);
    range.setStartAfter(br);
    range.setEndAfter(br);
    selection.removeAllRanges();
    selection.addRange(range);
    // Set cursor position right before the first letter after the line break
    if (selectionStart.node.nextSibling && selectionStart.node.nextSibling.nodeType === Node.TEXT_NODE) {
      range.setStart(selectionStart.node.nextSibling, 1);
      range.setEnd(selectionStart.node.nextSibling, 1);
    }
    selection.removeAllRanges();
    selection.addRange(range);
  }
}

export function getCustomInputTextFromHTML(html: string): string {
  const container = document.createElement('div');
  container.innerHTML = html;

  const images: HTMLImageElement[] = Array.from(container.querySelectorAll('img'));
  const spans: HTMLSpanElement[] = Array.from(container.querySelectorAll('span'));

  images.forEach(image => {
    container.innerHTML = container.innerHTML.replace(image.outerHTML, `:${image.dataset.emoji}:`);
  });

  spans.forEach(span => {
    container.innerHTML = container.innerHTML.replace(span.outerHTML, span.innerText + ' ');
  });

  return container.innerHTML;
}
