import clsx from 'clsx';
import EmojiPicker, { EmojiStyle, SuggestionMode } from 'emoji-picker-react';
import { MouseDownEvent } from 'emoji-picker-react/dist/config/config';
import { useCallback, useState } from 'react';

import { IEmoji } from '../../types';
import './emoji-sticker-picker.style.scss';
import { IEmojiStickerPickerProps } from './emoji-sticker-picker.type';

const classNamePrefix = 'tt-ui-chat-widget__emoji-sticker-picker';

export const EmojiStickerPicker: React.FC<IEmojiStickerPickerProps> = props => {
  const { onEmojiClick, onStickerClick, emojiStickerGroups, className, style, variant } = props;
  const [currentTabIdx, setCurrentTabIdx] = useState(0);

  const handleEmojiClick: MouseDownEvent = useCallback(
    (emoji: { emoji: string; imageUrl: string; isCustom: boolean }) => {
      onEmojiClick?.({
        id: emoji.emoji,
        name: emoji.emoji,
        native: emoji.isCustom ? undefined : emoji.emoji,
        isCustom: emoji.isCustom,
        imageUrl: emoji.imageUrl
      });
    },
    [onEmojiClick]
  );

  const currentTab = emojiStickerGroups?.[currentTabIdx];

  const renderPickerByVariant = useCallback(() => {
    switch (variant) {
      case 'emoji-sticker': {
        return (
          <>
            {!!emojiStickerGroups?.length && (
              <div className={`${classNamePrefix}__tab-bar`}>
                {emojiStickerGroups?.map((tab, idx) => (
                  <div
                    key={tab.id}
                    className={clsx(`${classNamePrefix}__tab-bar-item`, {
                      active: currentTab?.id === tab.id
                    })}
                    onClick={() => setCurrentTabIdx(idx)}
                  >
                    <img className={`${classNamePrefix}__tab-bar-item-icon`} src={tab.iconImageUrl} alt="" />
                  </div>
                ))}
              </div>
            )}
            <div
              className={clsx(`${classNamePrefix}__picker`, {
                'emoji-picker': currentTab?.type === 'emoji',
                'sticker-picker': currentTab?.type === 'sticker'
              })}
            >
              {currentTab?.items.map(item => (
                <div
                  key={item.id}
                  onClick={() => {
                    if (currentTab.type === 'emoji') {
                      onEmojiClick?.(item as IEmoji);
                      return;
                    }
                    onStickerClick?.(item);
                  }}
                >
                  <img src={item.imageUrl} alt={item.id} />
                </div>
              ))}
            </div>
          </>
        );
      }
      default: {
        return (
          <EmojiPicker
            skinTonesDisabled
            searchDisabled
            suggestedEmojisMode={SuggestionMode.FREQUENT}
            height="100%"
            width="100%"
            previewConfig={{
              showPreview: false
            }}
            customEmojis={currentTab?.items.map(item => ({
              id: item.id,
              names: [item.name],
              imgUrl: item.imageUrl
            }))}
            emojiStyle={EmojiStyle.NATIVE}
            onEmojiClick={handleEmojiClick}
          />
        );
      }
    }
  }, [currentTab, emojiStickerGroups, handleEmojiClick, onStickerClick, onEmojiClick, variant]);

  return (
    <div className={clsx(classNamePrefix, className)} style={style}>
      {renderPickerByVariant()}
    </div>
  );
};
