export interface ISticker {
  id: string;
  name: string;
  imageUrl: string;
}

export interface IStickerGroup {
  id: string;
  iconImageUrl: string;
  items: ISticker[];
  type: 'sticker';
}
