import clsx from 'clsx';
import React, { useMemo } from 'react';

import { EMOJI_REGEX_STRING, MENTION_REGEX_STRING } from '../../util';
import './message-text.style.scss';
import { IMessageTextProps } from './message-text.type';

const classNamePrefix = 'tt-ui-chat-widget__message-text';

const extractMentionComponents = (text: string) => {
  if (!text) {
    return [];
  }
  const components: React.ReactNode[] = [];
  const regex = new RegExp(MENTION_REGEX_STRING, 'g');
  let regExeArr: RegExpExecArray | null;
  let lastIndex = 0;

  while ((regExeArr = regex.exec(text))) {
    const matched = regExeArr[0];

    const subText = text.slice(lastIndex, regExeArr.index);
    if (subText) {
      components.push(subText);
    }

    components.push(
      <span key={`mention_${lastIndex}`} className={`${classNamePrefix}__mention`}>
        {matched}
      </span>
    );

    lastIndex = regExeArr.index + matched.length;
  }

  // Add the rest of the text
  if (lastIndex < text.length) {
    const subText = text.slice(lastIndex);
    if (subText) {
      components.push(subText);
    }
  }

  return components;
};

export const MessageText: React.FC<IMessageTextProps> = React.memo(props => {
  const { text, customEmojis, className, ...rest } = props;

  const children = useMemo(() => {
    if (!text) {
      return text;
    }

    const components: React.ReactNode[] = [];
    const regex = new RegExp(EMOJI_REGEX_STRING, 'g');
    let regExeArr: RegExpExecArray | null;
    let lastIndex = 0;

    // Find all emojis in the text
    while ((regExeArr = regex.exec(text))) {
      const matched = regExeArr[0];
      const emojiName = matched.replace(/:/g, '');
      const emojiData = customEmojis[emojiName];

      components.push(...extractMentionComponents(text.slice(lastIndex, regExeArr.index)));

      if (emojiData?.isCustom) {
        components.push(
          <img
            key={`${emojiName}_${lastIndex}`}
            className={`${classNamePrefix}__emoji`}
            data-emoji={emojiName}
            src={emojiData.imageUrl}
            alt={emojiName}
          />
        );
      } else {
        components.push(matched);
      }

      lastIndex = regExeArr.index + matched.length;
    }

    // Add the rest of the text
    if (lastIndex < text.length) {
      components.push(...extractMentionComponents(text.slice(lastIndex)));
    }

    return components;
  }, [text, customEmojis]);

  return (
    <div {...rest} className={clsx(classNamePrefix, className)}>
      {children}
    </div>
  );
});
