import clsx from 'clsx';
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import { addLineBreak, getCustomInputTextFromHTML, handlePasteHtmlAtCaret, replaceImageWithEmoji } from '../../util';
import './custom-input.style.scss';
import { ICustomInputProps, ICustomInputRef } from './custom-input.type';

const classNamePrefix = 'tt-ui-chat-widget__custom-input';

export const CustomInput = forwardRef<ICustomInputRef, ICustomInputProps>((props, ref) => {
  const { placeholder, style, tabIndex, className, onClick, onChange, onEnter, onChangeHtml, ...rest } = props;
  const textInputRef = useRef<HTMLDivElement | null>(null);
  const editorPrevValueRef = useRef<string>();

  const getSanitizedText = useCallback(() => {
    if (!textInputRef.current) {
      return '';
    }
    return getCustomInputTextFromHTML(textInputRef.current.innerHTML).replace(/<br>/g, '\n');
  }, []);

  const emitChangeEvents = useCallback(() => {
    if (!textInputRef.current) return;
    if (editorPrevValueRef.current === textInputRef.current.innerHTML) return;

    editorPrevValueRef.current = textInputRef.current.innerHTML;
    onChangeHtml?.(textInputRef.current.innerHTML);
    onChange?.(getSanitizedText());
  }, [getSanitizedText, onChange, onChangeHtml]);

  useImperativeHandle(
    ref,
    () => ({
      insertContentAtCaret: html => {
        if (!textInputRef.current) return;

        textInputRef.current.focus();
        handlePasteHtmlAtCaret(html);
        emitChangeEvents();
      },
      setHtml(html) {
        if (!textInputRef.current) {
          return;
        }

        textInputRef.current.innerHTML = html;
        emitChangeEvents();
      },
      getHtml() {
        if (!textInputRef.current) return '';

        return textInputRef.current.innerHTML;
      },
      getText() {
        return getSanitizedText();
      },

      focus() {
        if (!textInputRef.current) return;

        textInputRef.current.focus();
      }
    }),
    [emitChangeEvents, getSanitizedText]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (event.shiftKey) {
          if (textInputRef.current) {
            addLineBreak();

            emitChangeEvents();
            return;
          }
        }

        onEnter?.(event);
        return;
      }

      emitChangeEvents();
    },
    [emitChangeEvents, onEnter]
  );

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      onClick?.(event);
      if (!event.defaultPrevented && textInputRef.current) {
        textInputRef.current.focus();
      }
    },
    [onClick]
  );

  const handleInput = useCallback(() => {
    if (textInputRef.current) {
      onChange?.(textInputRef.current.innerHTML);
    }
  }, [onChange]);

  const handleCopy = useCallback((event: React.ClipboardEvent) => {
    const selectedText = window.getSelection();

    if (selectedText === null) {
      return;
    }

    let container = document.createElement('div');

    for (let i = 0, len = selectedText.rangeCount; i < len; ++i) {
      container.appendChild(selectedText.getRangeAt(i).cloneContents());
    }

    container = replaceImageWithEmoji(container);

    event.clipboardData.setData('text', container.innerText);
    event.preventDefault();
  }, []);

  const handlePaste = useCallback(
    (event: React.ClipboardEvent) => {
      event.preventDefault();
      const text = event.clipboardData.getData('text/plain');

      if (!textInputRef.current) return;
      handlePasteHtmlAtCaret(text);
      emitChangeEvents();
    },
    [emitChangeEvents]
  );

  return (
    <div
      {...rest}
      data-placeholder={placeholder}
      ref={textInputRef}
      className={clsx(classNamePrefix, className)}
      style={style}
      onKeyDown={handleKeyDown}
      onInput={handleInput}
      tabIndex={tabIndex}
      contentEditable
      onCopy={handleCopy}
      onPaste={handlePaste}
      onClick={handleClick}
    />
  );
});
