export interface IEmoji {
  id: string;
  name: string;
  native?: string;
  isCustom: boolean;
  imageUrl: string;
}

export interface IEmojiGroup {
  id: string;
  iconImageUrl: string;
  items: IEmoji[];
  type: 'emoji';
}

export type TEmojiName = string;
